import React from "react"
import Helmet from "react-helmet"
import Uninstall from "@tightrope/footerlinks/uninstall"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UninstallPage() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Uninstall - Aioli Sous Chef</title></Helmet>
    <Uninstall></Uninstall>
    </HomepageLayout>
  )
}
